<script setup>
import { ref, defineProps } from 'vue';
import IcerikLogoIcon from '@/assets/icons/icerik-logo-icon.svg';

const props = defineProps({
  actionButton: { type: Object, default: () => ({}) },
  menuItems: { type: Array, default: () => [] },
});

const isSidebarShrink = ref(false);
const handleSidebarShrink = () => {
  isSidebarShrink.value = !isSidebarShrink.value;
};
</script>

<template>
  <aside
    class="fixed top-0 left-0 h-full bg-white z-50"
    :class="{
      'w-[80px] p-4 overflow-hidden': isSidebarShrink,
      'w-[240px] p-6': !isSidebarShrink,
      'sidebar-shrink': isSidebarShrink,
    }"
  >
    <nav class="flex flex-col justify-center items-center h-full">
      <a
        class="cursor-pointer"
        :class="{
          'max-w-[32px] overflow-hidden': isSidebarShrink,
          'mb-5': !props.actionButton
        }"
        @click="$router.push({ name: 'Dashboard' })"
      >
        <icerik-logo-icon class="h-[18px]" />
      </a>

      <ul class="h-full w-full">
        <template v-if="props.actionButton">
          <li class="p-0 mt-7 mb-5">
            <router-link
              :to="actionButton.to"
              class="no-underline w-full bg-light px-4 py-2 text-primary flex justify-between
                items-center gap-2 rounded-lg font-sans-medium text-sm"
            >
              <span
                v-if="!isSidebarShrink"
                class="name"
              >
                {{ actionButton.text }}
              </span>
              <i class="icon icon-plus text-primary m-0 text-xl font-bold" />
            </router-link>
          </li>
        </template>
      
        <template
          v-for="menuItem in props.menuItems"
          :key="menuItem.to"
        >
          <li class="p-0 py-1">
            <router-link
              :to="menuItem.to"
              class="flex items-center gap-2 font-sans-medium p-2 px-4 text-sm text-i-purple-gray hover:text-primary
                rounded-lg w-full no-underline"
            >
              <template v-if="isSidebarShrink">
                <i :class="menuItem.icon" />
              </template>

              <template v-else>
                <i :class="menuItem.icon" />
                <span class="name">{{ menuItem.text }}</span>
              </template>
            </router-link>
          </li>
        </template>
      </ul>

      <div class="flex justify-end w-full">
        <button
          class="h-[36px] w-[36px] flex bg-light cursor-pointer items-center justify-center rounded"
          @click="handleSidebarShrink"
        >
          <i
            class="icon text-xl"
            :class="{
              'icon-chevron-left': !isSidebarShrink,
              'icon-chevron-right': isSidebarShrink,
            }"
          />
        </button>
      </div>
    </nav>
  </aside>
</template>

<style lang="scss">
aside {
  .active, .router-link-exact-active {
    @apply bg-i-light text-primary #{!important};
  }
}

#general-container {
  margin-left: 240px;
  max-width: calc(100% - 240px);
}
#general-container:has(aside.sidebar-shrink) {
  margin-left: 80px;
  max-width: calc(100% - 80px);
}

@media (min-width: 1920px) {
    .container-fluid {
        max-width: 1700px !important;
        margin: auto !important;
    }
}
</style>
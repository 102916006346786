export default [
  {
    path: '/mesajlar',
    name: 'MessagePage',
    redirect: {
      name: 'MessageReceivedPage',
    },
    component: () => import('../views/Message/MessagePage.vue'),
    children: [
      {
        path: 'gelen',
        name: 'MessageReceivedPage',
        component: () => import('../views/Message/MessagesPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'gonderilen',
        name: 'MessageSentPage',
        component: () => import('../views/Message/MessagesPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: ':id',
        name: 'MessageDetailPage',
        component: () => import('../views/Message/MessageDetailPage.vue'),
        meta: {
          protected: true,
        }
      },
      {
        path: 'yeni',
        name: 'NewMessagePage',
        component: () => import('../views/Message/NewMessagePage.vue'),
        meta: {
          protected: true,
        }
      }
    ],
    meta: {
      protected: true,
    }
  },
];